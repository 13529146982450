import { trackDOMEvent, trackViewEvent } from '~/components/shared/tracking/segmentAnalytics';

export function closeModal(modalEl, overlayEl) {
  if (modalEl.dataset.trackModalClose && modalEl.classList.contains("opened")) {
    modalEl.dataset.segmentEvent = "Directory - Modal"
    trackDOMEvent({ currentTarget: modalEl })
  }
  modalEl.classList.remove('opened');
  document.body.classList.remove('no-scroll');
  if (overlayEl) {
    overlayEl.classList.remove('opened');
  }

}

export function openModal(modalEl, overlayEl) {
  modalEl.classList.add('opened');
  if (typeof modalEl.dataset.segmentManualView !== 'undefined') {
    trackViewEvent(modalEl);
  }
  document.body.classList.add('no-scroll');
  if (overlayEl) {
    overlayEl.classList.add('opened');
    if (typeof modalEl.dataset.clickOutsideToClose !== 'undefined') {
      overlayEl.addEventListener('click', (e) => {
        e.stopPropagation();
        if (e.target === overlayEl) {
          closeModal(modalEl, overlayEl);
        }
      }, { once: true });
    }
  }
}

// this allows us to override in case we do not want a particular element activating the modal
function ignoreModal(target) {
  const targetContainsModalIgnore = target.dataset.modalIgnore !== null && target.dataset.modalIgnore !== undefined;
  const targetParent = target.parent;
  let targetParentContainsModalIgnore = false;
  if (targetParent !== undefined && targetParent !== null) {
    targetParentContainsModalIgnore = targetParent.dataset.modalIgnore !== null && targetParent.dataset.modalIgnore !== undefined;
  }
  return targetContainsModalIgnore || targetParentContainsModalIgnore;
}

export function setUpModals() {

  document.querySelectorAll('[data-modal-name]').forEach(modal => {
    const overlayEl = document.querySelector('.generic-modal__overlay');
    // set up close button
    modal.querySelector('[data-close]').addEventListener('click', () => {
      closeModal(modal, overlayEl);
    });
    // set up activator
    const name = modal.dataset.modalName;
    document.querySelectorAll(`[data-modal-activator="${name}"]`).forEach(activatorEl => {
      activatorEl.addEventListener('click', (e) => {
        if (!ignoreModal(e.target)) {
          openModal(modal, overlayEl);
        }
      });
    });

  });
}


export function setUpModalsOnMap() {
  const overlayEl = document.querySelector('.generic-modal__overlay');
  document.querySelectorAll(`[data-map-modal-activator]`).forEach(activatorEl => {
    let name = activatorEl.dataset.modalActivator;
    let modal = document.querySelector(`[data-modal-name="${name}"]`)

    if (modal) {
      activatorEl.addEventListener('click', (e) => {
        if (!ignoreModal(e.target)) {
          openModal(modal, overlayEl);
        }
      });
    }
  });
}
